<template>
  <div class="login">
    <a-card class="box-card">
      <h1>注册</h1>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            allowClear
            placeholder="邮箱地址"
            @change="changeEmail"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入邮箱地址',
                  },
                  {
                    type: 'email',
                    message: '邮箱格式有误',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="mail" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="邮箱验证码"
            v-decorator="[
              'emailcode',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入验证码',
                  },
                  {
                    len: 6,
                    message: '验证码格式有误',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="code" style="color:rgba(0,0,0,.25)" />
            <a-button 
              slot="suffix" 
              type="link" 
              class="emailcode-btn"
              :disabled="btnStyle !== 'actived'"
              @click="sendEmailCode"
            >{{ btnText }}</a-button>
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="用户名"
            v-decorator="[
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入用户名',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input-password
            allowClear
            placeholder="密码"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-item>

        <a-form-item style="margin: 0">
          <a-button type="primary" html-type="submit" size="large" @click="handleSubmit">
            注册
          </a-button>

          <p class="desc">
            已有账号？ <router-link to="/user/login">登录</router-link>
          </p>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { Encrypt } from '@/utils';
export default {
  name: 'register',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'registerForm' }),
      timer: null,
      ajaxFlag: true,
      email: null,
      btnText: '发送验证码',
      btnStyle: 'actived',
      num: 60,
    };
  },
  methods: {
    changeEmail(e) {
      this.email = e.target.value;
    },
    async sendEmailCode() {
      if (!this.email) {
        this.form.setFields({
          email: {
            value: '',
            errors: [new Error('请输入邮箱')],
          },
        });
        return;
      }
      const res = await this.$store.dispatch('sendEmailCode', { email: this.email, type: 'register'});
      if (res.code === 0) {
        this.interval();                                      //执行倒计时
        this.$message.success(`已将验证码发送到您${this.email}的邮箱当中，请注意查收！`);
      } else {
        this.$message.error('抱歉，邮件发送失败！');
      }
    },
    //短信倒计时
    interval() {
      let num = 60;
      this.btnText = '重新发送(' + num + 's)';
      this.btnStyle = 'disabled';
      this.timer = setInterval(() => {
        if (num === 1) {
          this.ajaxFlag = true;
          this.btnText = '重新获取';
          this.btnStyle = this.email ? 'actived' : null,
          this.num = 60;
          clearInterval(this.timer);
        } else {
          num--;
          this.btnText = '重新发送(' + num + 's)';
          this.num = num;
        }
      }, 1000)
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.password = Encrypt(values.email, values.password);
          this.$store.dispatch('register', values);
        }
      });
    }
  },
};
</script>

<style lang="scss">
.login {
  .box-card {
    width: 400px;
    margin: auto;
  }
  h1 {
    font-size: 18px;
    font-weight: 400;
  }
  button {
    width: 100%;
  }
  .desc {
    margin-top: 20px;
  }
  .ant-form-explain {
    text-align: left;
  }
  .emailcode-btn {
    color: var(--content-color-tertiary);
    &:hover {
      color: var(--primary-color);
    }
  }
}
</style>
